.bubbles {

}

.bubble {
  position: absolute;
  bottom: -100px;
  width: 20px;
  height: 20px;
  background: #f1f1f125;
  border-radius: 50%;
  opacity: 0.5;
  animation: rise 50s infinite ease-in;
}

.bubble:nth-child(1) {
  width: 20px;
  height: 20px;
  left: 10%;
  animation-duration: 36s;
}

.bubble:nth-child(2) {
  width: 10px;
  height: 10px;
  left: 20%;
  animation-duration: 55s;
  animation-delay: 17s;
}

.bubble:nth-child(3) {
  width: 20px;
  height: 20px;
  left: 35%;
  animation-duration: 17s;
  animation-delay: 15s;
}

.bubble:nth-child(4) {
  width: 20px;
  height: 20px;
  left: 50%;
  animation-duration: 31s;
  animation-delay: 0s;
}

.bubble:nth-child(5) {
  width: 15px;
  height: 15px;
  left: 55%;
  animation-duration: 16s;
  animation-delay: 21s;
}

.bubble:nth-child(6) {
  width: 25px;
  height: 25px;
  left: 65%;
  animation-duration: 18s;
  animation-delay: 8s;
}

@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }

  50% {
    transform: translate(100px);
  }

  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}
